<template>
    <b-row class="match-height">
  <!-- Modal Edit Password-->
  <b-modal
    id="modalEditPassword"
    v-model="modalEditPassword"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    title="Edit Password"
  >
    <b-form>
  <div>
        <form
id="editPasswordForm"
method="post"
@submit.prevent="submitEditPassword"
action=""
>
          <b-row>
          <b-col
        cols="12"
        md="12"
        >
          <b-form-group
            label="Old Password"
            label-for="oldPassword"
          >
          <input
v-model="oldPassword"
type="password"
class="form-control"
placeholder="Write your old password"
>
</b-form-group>
          </b-col> <b-col cols="6" md="6">
        <b-form-group label="New Password" label-for="newPassword">
          <div class="input-group">
            <b-form-input
              v-model="newPassword"
              class="form-control"
              :type="passwordField"
              placeholder="Write your new password"
            />
            <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVis"
                      />
            </b-input-group-append>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6">
        <b-form-group label="Confirm New Password" label-for="confirmNewPassword">
          <div class="input-group">
            <b-form-input
              v-model="confirmNewPassword"
              :type="passwordFieldConfirm"
              class="form-control"
              placeholder="Confirm your new password"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIconConfirm"
                @click="togglePasswordVisConfirm"
              />
            </b-input-group-append>
          </div>
        </b-form-group>
      </b-col>
          <b-col
        cols="11"
        md="3"
><input
type="submit"
class="btn btn-primary pull-right"
value="Send"
></b-col>
        </b-row>
          </form>
      </div>
    </b-form>
  </b-modal>
      <b-col
  lg="8"
  offset="2"
  > <b-card
      title="Profile"
    >

      <b-form
          @submit.prevent="submitUpdateProfile"
  >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Full Name *"
              label-for="name"
              label-cols-md="4"
            >
              <b-form-input
                id="fullname"
                v-model="Name"
                placeholder="Full Name"
                required="required"
              />
            </b-form-group>
            <b-form-group
              label="Phone *"
              label-for="phone"
              label-cols-md="4"
            >
              <b-form-input
                id="phone"
                v-model="Phone"
                placeholder="Phone"
                required="required"
              />
            </b-form-group>
            <b-form-group
              label="Occupation *"
              label-for="occupation"
              label-cols-md="4"
            >
              <b-form-input
                id="occupation"
                v-model="Occupation"
                placeholder="Occupation"
                required="required"
              />
            </b-form-group>
            <b-form-group
              label="Signature *"
              label-for="signature"
              label-cols-md="4"
            >
          <b-form-file
            id="signature"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
            </b-form-group>
             <p />
             <div class="text-center">
             <b-img width="200px" :src="Signature" rounded alt="Signature" v-if="Signature!=''">
          </b-img>
          </div>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <br>
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Save Changes
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="modalEditPassword=true"
            >
              Change Password
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile, BImg, BInputGroupAppend,
    } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
import crypto from 'crypto'
  import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const file = ''
  const items = []
  const chosenPR = ref()
  const chosens = []
  const shipmentTo = ''
  const itemId = ''
  const Name = ''
  const Phone = ''
  const Occupation = ''
  const Signature = ''
  const purchaseOrderQtyRequested = 0
  const purchaseOrderStatus = ''
  const purchaseOrderNotes = ''
  const confirmNewPassword = ''
  const oldPassword = ''
  const deliverySchedule = ''
  const modalReferenceShow = false
  const itemReference = ''
  const itemLink = ''
  const itemPrice = ''
  const itemDescription = ''
  const itemSupplier = ''
  const contacts = []
  const branches = []
  const references = []
  const currencies = []
  const types = ['Center','Cabang']
  const chosenType = ''
  const chosenBranch = ''
  const chosenContact = ''
  const termOfPayment = ''
  const billingAddress = ''
  const deliveryAddress = ''
  const itemCurrency = ''
  const paymentTypes = ['Credit', 'Cash', 'COD']
  const chosenPaymentType = ''
  const modalReferenceHistoryShow = false

  export default {
    components: {
        BImg,
        BInputGroupAppend,
        BFormFile,
      BFormDatepicker,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
  mixins: [togglePasswordVisibility],
    data() {
      return {
        passwordFieldConfirm: 'password',
        passwordField: 'password',
        oldPassword,
        confirmNewPassword,
      showNewPassword: false,
      showConfirmPassword: false,
        Signature,
        Name,
        Phone,
        Occupation,
        chosenType,
        types,
        itemSupplier,
        paymentTypes,
        chosenPaymentType,
        itemCurrency,
        currencies,
        deliveryAddress,
        chosenBranch,
        branches,
        termOfPayment,
        billingAddress,
        modalReferenceHistoryShow,
        chosenContact,
        contacts,
        itemId,
        deliverySchedule,
        file,
        modalReferenceShow,
        itemReference,
        itemLink,
        itemPrice,
        itemDescription,
        references,
      VersionCheck,
      items,
      chosenPR,
      chosens,
      shipmentTo,
      purchaseOrderQtyRequested,
      purchaseOrderStatus,
      purchaseOrderNotes,
      modalEditPassword: false,
      realPassword: '',
      newPassword: '',
      }
    },
  computed: {
    passwordToggleIcon() {
      return this.passwordField !== 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldConfirm !== 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
    mounted() {
        this.getDetails()
    },
    methods: {
      togglePasswordVis() {
        this.passwordField = this.passwordField === 'password' ? '' : 'password'
      },
      togglePasswordVisConfirm() {
        this.passwordFieldConfirm = this.passwordFieldConfirm === 'password' ? '' : 'password'
      },
        getDetails() {
            const userData = this.$cookies.get('userData')
            this.Name = userData.user_fullname
            this.Phone = userData.user_phone
            this.Occupation = userData.user_occupation
            this.Signature = `${process.env.VUE_APP_API_URL}upload/${userData.user_signature}`
            this.realPassword = userData.user_password
        },
        submitEditPassword() {
          const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        if (this.oldPassword !== "" && this.newPassword !== "") {
      if (this.newPassword === this.confirmNewPassword) {
          const _oldPassword = crypto.createHmac('sha256', process.env.VUE_APP_KEY)
                   .update(this.oldPassword)
                   .digest('hex')
                   const _newPassword = crypto.createHmac('sha256', process.env.VUE_APP_KEY)
                   .update(this.newPassword)
                   .digest('hex')
            const payload = {
              oldPassword: _oldPassword,
                newPassword: _newPassword,
                time: moment(),
              }
              axios
              .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_PASSWORD_USER}`, payload, { headers })
              .then(response => {
                if (response.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Change Password Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
              })
        } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Your Password does not match',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
        }
        } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Please fill all the form fields',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
        }
        },
    submitUpdateProfile() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      }
      formData.append('user_fullname', this.Name)
      formData.append('user_phone', this.Phone)
      formData.append('user_occupation', this.Occupation)
      formData.append('time', moment())
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_UPDATE_USER}`, formData, { headers: header })
        .then(response => {
          if (response.data.success === true) {
              this.$cookies.set('userData', response.data.data)
            this.$router.replace({ name: 'apps-users-profile' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Profile Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Update Profile Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Purchase Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
            axios
            .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_DETAILS}${val.value}`, { headers })
            .then(resp => {
              if (resp.data.success === true) {
              const itemsDetails = []
              resp.data.data.map(elem => {
                itemsDetails.push({
                  id: elem.purchase_request_detail_id, label: elem.item.item_name, value: elem.purchase_request_detail_item_id, price: elem.item.item_price, qty: elem.purchase_request_detail_item_qty_accumulation, cheapest: elem.item.item_cheapest_price, currency: elem.item.currency.currency_symbol,
                })
              })
              this.chosens = itemsDetails
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Get Data Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Get Data Failed',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
      addReference(val) {
        this.modalReferenceShow = true
        this.itemId = val
      },
      showReference(val) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        this.itemId = val
        this.modalReferenceHistoryShow = true
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                this.references = response.data.data
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
      choose(referenceId, itemIdRef, price) {
        const index = this.chosens.findIndex(x => x.value === itemIdRef)
        this.chosens[index].reference_id = referenceId
        this.chosens[index].price = price
        this.modalReferenceHistoryShow = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Price Chosen',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      },
      saveNewReference() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        this.modalReferenceShow = false
        const body = {
          item_reference_item_id: this.itemId,
          item_reference_link: this.itemLink,
          item_reference_price: this.itemPrice,
          item_reference_description: this.itemDescription,
          item_reference_currency_id: this.itemCurrency.value,
          item_reference_supplier: this.itemSupplier,
          time: moment(),
        }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_ITEM_LOOKUP}`, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.itemId = ""
            this.itemLink = ""
            this.itemPrice = ""
            this.itemDescription = ""
            this.itemCurrency = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'New Reference Added',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Adding New Reference Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Adding New Reference Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        this.itemReference = ""
        this.itemLink = ""
        this.itemPrice = ""
        this.itemDescription = ""
        this.itemSupplier = ""
        this.modalReferenceShow = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data Saved',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      },
    },
  }
  </script>